
body.profile-page::before {
   content: "";
    position: fixed; /* Фиксиране към екрана */
    top: 0;
    left: 0;
    width: 100vw; /* Използвай 100% от ширината на прозореца */
    height: 100vh; /* Използвай 100% от височината на прозореца */
  background-image: url('/pexels-victorfreitas-841130.jpg'); /* Replace with the actual image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}


.profile-container {
  max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
     position: relative;
     background: transparent; /* Remove background color */
     z-index: 1; /* Ensure content is above the background */
}


.profile-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);/* Semi-transparent white */
  backdrop-filter: blur(30px); /* Add blur effect */
  -webkit-backdrop-filter: blur(30px); /* For Safari */
  z-index: -1; /* Place behind the content */
  border-radius: 30px; /* Match the container's border-radius */
}

/* Set a consistent width for the form elements */
.update-weight input,
.set-goals input,
.update-measurements input {
  width: 100%; /* Make sure inputs take full width of their container */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Ensure the buttons take appropriate space */


/* Set a minimum width for the buttons */
button + button {
  margin-left: 10px;
}

.profile-container h1 {
  text-align: center;
  color: #333;

}

.profile-details p {
  font-size: 18px;
  color: #555;


}

.profile-details p strong {
  color: #000;
}
.update-weight {
  margin: 10px;
  margin: 10px;

  margin-top: 30px;
  text-align: center;
}

.update-weight h2 {
  color: #333;
  margin-bottom: 20px;
}

.input-button-container {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 10px; /* Adjust spacing between input and button */
}

.update-weight input {
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
}

.update-weight button {
width: 30%;
  padding: 10px 15px;
  border: none;

  color: white;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
}





.all-macros {
  margin-top: 15px;
    margin-bottom: -20px;

}

.all-macros h2 {
  color: #333;
  margin-bottom: 20px;
}

.all-macros ul {
  list-style-type: none;
  padding: 0;
}

.macro-item {
  background: #f4f4f4;
  margin-bottom: 10px;
  padding: 10px;

  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.macro-date {
  font-size: 16px;
  font-weight: bold;
  color: red;
}

.macro-details p {
  font-size: 14px;
  margin: 2px 0;

    color: #333 !important;
}

.set-goals h2 {
   color: #333;
   margin-bottom: 20px;
   margin-top: 20px!important;


 }

.set-goals {
  text-align: center;
   background: rgba(255, 255, 255, 0.4); /* Semi-transparent white */
    backdrop-filter: blur(30px); /* Add blur effect */
    -webkit-backdrop-filter: blur(30px); /* For Safari */
    border-radius: 10px;
    margin-bottom: 20px;
}


.input-container {
  display: flex;
  justify-content: center;
  gap: 15px!important; /* Adds spacing between input fields */
  flex-wrap: wrap; /* Ensures responsiveness on smaller screens */
  margin-bottom: 20px !important;

}
.set-goals input:not(:last-child) {
  margin-right: 10px; /* Extra space between inputs */
}

.set-goals button {
  width: 80%;
  margin-top: 15px;
  padding: 10px 15px;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
}

.set-goals input {
  width: 18%; /* Adjust to fit four inputs in a row */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 16px;
}

 .weight-records ul {
   list-style: none;
   padding: 0;
 }

 .weight-records li {
   background: #dcfae3;
   padding: 10px;
   margin-bottom: 5px;
   border-radius: 5px;
 }

 button + button {
   margin-left: 10px; /* Add space between buttons */
 }
.update-weight,
.set-goals,
.weight-records,
.all-macros {
  margin-bottom: 20px;
}

.status {
  padding: 10px;
  background-color: #e9f7e9;


  border-radius: 20px;
}

.status h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.status select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 30px;
  margin-bottom: 10px;
}

.status p {
  font-size: 16px;
  margin-top: 10px;
  color: #333;
  font-weight: bold;
}

/* Measurements Section Styles */
.update-measurements {
  margin: 20px 0;
  padding: 15px;
  background: rgba(255, 255, 255, 0.5);/* Semi-transparent white */
    backdrop-filter: blur(30px); /* Add blur effect */
    -webkit-backdrop-filter: blur(30px); /* For Safari */

  border-radius: 10px;
}

.update-measurements h2 {
  margin-bottom: 15px;
  margin-top: -5px;
  font-size: 1.5rem;
  color: #333;
}

.update-measurements input {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-sizing: border-box;
}



/* Latest Measurement Styles */
.latest-measurement {
  margin-top: 20px;
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.latest-measurement h2 {
  margin-top: 5px;
  font-size: 1.5rem;
  color: #555;
}

.latest-measurement p {
  margin: 5px 0;
  font-size: 1rem;
  color: #666;
}

/* Measurement Records Styles */
.measurement-records {
  margin-top: 15px;
}

.measurement-records button {

  padding: 10px 20px;
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.measurement-records button:hover {
  background-color: #007bb5;
}

.measurement-records ul {
  list-style-type: none;
  padding-left: 0;
}

.measurement-records li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
  color: #333;
}

.measurement-records li:last-child {
  border-bottom: none;
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-row button {
  flex: 1;
  margin: 0 5px;
  padding: 12px 12px;
  font-size: 0.9rem;
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.button-row button:hover {
  background-color: #007bb5;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 12px 0;
   border-spacing: 0; /* Remove space between cells */
     border-radius: 12px; /* Rounded edges for the entire table */
     overflow: hidden; /* Ensure rounded edges are applied */
}

th, td {
  padding: 7px;
  text-align: left;
  border-bottom: 1px solid #a0cfde;
}

th {
  background-color: #a0cfde;
  font-weight: bold;
}

td {
 background-color: #fff;
  color: #333;
  font-size: 14px;
}

.profile-details {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

}

.detail-card {

  align-items: center; /* Vertically align items */
  justify-content: center; /* Center the content */
  gap: 10px; /* Add space between the image and text */
  border-radius: 20px;
  padding: 10px;
  margin-top: 15px;
  width: 140px;
  height: 100px;
  text-align: center;
  background: rgba(255, 255, 255, 0.4); /* Semi-transparent white */
  backdrop-filter: blur(30px); /* Add blur effect */
  -webkit-backdrop-filter: blur(30px); /* For Safari */
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
}

.detail-card1{
 position: relative;
  border-radius: 20px;
  padding: 10px;
  width: 130px;
  height: 130px;
  text-align: center;

  background: rgba(255, 255, 255, 0.55);/* Semi-transparent white */
    backdrop-filter: blur(30px); /* Add blur effect */
    -webkit-backdrop-filter: blur(30px); /* For Safari */

  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);


}

.detail-image {
  width: 110%; /* Make the image fit the card */
  height: 110%; /* Stretch to cover the height */
  object-fit: cover; /* Maintain aspect ratio and fill the card */
  margin-left:-7px;
  margin-top:-7px;

  border-radius: 20px; /* Match the card's rounded corners */
}

.scale {
  position: absolute; /* Position relative to the .detail-card1 container */
  top: 10px; /* Adjust to position the box */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center the box relative to its width */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 80px;
  height: 30px;
  text-align: center;
  background: rgba(255, 255, 255, 0.75); /* Semi-transparent background */
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

}

.activity-image {
  width: 60px; /* Adjust the size of the image */
  height: 60px;

  object-fit: cover; /* Maintain aspect ratio */
}

.status-image{
         width: 60px; /* Adjust the size of the image */
         height: 60px;

           margin-top:-10px;


         object-fit: cover; /* Maintain aspect ratio */
            }

.active {
 color: #fff;
  display: flex; /* Enable Flexbox for text and image */
  flex-direction: row; /* Horizontal layout */
  align-items: center; /* Align content vertically */
  gap: 10px; /* Space between image and text */

}

.statuscont{
 color: #fff;
  display: flex; /* Enable Flexbox for text and image */
  flex-direction: row; /* Horizontal layout */
  align-items: center; /* Align content vertically */
  gap: 10px; /* Space between image and text */
   margin-top:-10px;
}




.icon {
  font-size: 2rem;
  margin-bottom: 10px;
  display: block;
    color: #fff;


}

p {
  font-size: 1.1rem;
  color: #333;
}

.measurement-body {
  position: relative;
  text-align: center;
}

.body-container {
  position: relative;
  display: inline-block;
  width: 600px; /* Ensure enough width for labels and body */
}

.body-image {
  width: 350px;
  height: auto;
    filter: invert(100%);
}

/* Shared styles for all measurement labels */
.measurement-label {
  position: absolute;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  width: 150px; /* Increase width to fit the text on one line */
  white-space: nowrap;
}

/* Custom positions for each label */
/* Shoulder on the left */
.shoulder {
  top: 20px;   /* Position shoulder */
  left: 80px; /* Place on the left */
}


/* Chest on the right */
.chest {
  top: 70px;
  left: 400px; /* Place on the right */
}



/* Biceps on the left */
.biceps {
  top: 60px;
  left: 70px;
}


/* Waist on the left */
.waist {
  top: 130px;
  left: 140px;
}



/* Hips on the right */
.hips {
  top: 150px;
  left: 340px;
}


/* Thigh on the right */
.thigh {
  top: 200px;
  left: 340px;
}



/* Calf on the right */
.calf {
  top: 250px;
  left: 340px;
}



.profileContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.profileDetailsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  max-width: 600px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profileBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #fafafa;
  border-radius: 8px;
}

.icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.update-status {

}
.update-container {
  display: flex;
  justify-content: center; /* Centers the content */
  align-items: center; /* Aligns items vertically */
  gap: 5px; /* Controls space between the sections */
  flex-wrap: wrap; /* Ensures responsiveness */
  margin-bottom: 5px!important;
  margin-top: -20px;
  padding: 10px; /* Adds spacing to prevent edge touching */
}

.update-status,
.update-activity {
  flex: 1; /* Allows them to take equal space */
  max-width: 300px; /* Adjusts the width */
  min-width: 250px; /* Prevents it from becoming too small */
  text-align: center;
  padding: 10px;
}

.update-status h3{
margin-left: 40px;
 margin-bottom: 10px;
  font-size: 18px;
  color: #fff;
}
.update-activity h3 {
margin-right: 40px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #fff;
}

.update-status select{
margin-left: 40px;
  width: 80%; /* Ensures the select boxes don't touch the container sides */
  height: 40px; /* Makes them taller */
  font-size: 18px; /* Increases readability */
  padding: 12px; /* Extra internal spacing */
  color: #555;
  border: 1px solid #ccc;
  border-radius: 20px; /* Smooth rounded edges */
  background-color: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.update-activity select {
margin-right: 40px;
  width: 80%; /* Ensures the select boxes don't touch the container sides */
  height: 40px; /* Makes them taller */
  font-size: 18px; /* Increases readability */
  padding: 12px; /* Extra internal spacing */
  color: #555;
  border: 1px solid #ccc;
  border-radius: 20px; /* Smooth rounded edges */
  background-color: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* Focus styling */
.update-status select:focus,
.update-activity select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Responsive Design */
@media (max-width: 768px) {
  .update-container {
    flex-direction: column; /* Stack items vertically on small screens */
    gap: 10px;
  }

  .update-status select,
  .update-activity select {
    width: 100%;
  }
}
select {
  width: 100%; /* Adjust to fit the container */
  max-width: 500px; /* Optional: Set a maximum width */
  height: 40px; /* Increase height for more space */
  font-size: 18px; /* Larger font size for better readability */
  padding: 10px; /* Add internal spacing for a spacious feel */
  border: 1px solid #ccc; /* Optional: Consistent border styling */
  border-radius: 20px; /* Rounded corners for a smoother look */
  background-color: #fff; /* Background color */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer; /* Change cursor to pointer for interactivity */
}

.automatic-set-goal-button {
  margin-top: -200px!important;
  margin-bottom: 20px!important;


  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.automatic-set-goal-button:hover {
  background-color: #007bb5;
}




.reminder-dot {
  width: 30px;
  height: 30px;
  background-color: red;
  border-radius: 50%;
  position: absolute;

}


.add-measurements-btn {
  position: relative;
  padding-right: 15px;
}









