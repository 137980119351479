
body.no-scroll {
  overflow: hidden;
}

body {

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100vh; /* Full viewport height */
  margin: 0;
  background: #fff;
  position: relative;
    margin-right: 10px;
}

body.login-page::before {
  content: "";
  position: absolute;
  top: -10px;
  width: calc(100% + 100px);
  height: calc(100% + 30px);
  background-image: url('/pexels-lum3n-44775-604969 copy.jpeg'); /* Replace with the actual image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  z-index: -1;
}

.container {
  max-width: 400px;
  margin: 400px auto;
  padding: 50px;
  color: white;
  border-radius: 30px;

  background: transparent; /* Remove background color */
  position: relative; /* Required for pseudo-element positioning */
  z-index: 1; /* Ensure content is above the background */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Add blur effect */
  -webkit-backdrop-filter: blur(20px); /* For Safari */
  z-index: -1; /* Place behind the content */
  border-radius: 30px; /* Match the container's border-radius */
}

h1 {
text-align: center; /* Center-align the text */
   margin-top: 1px;
  color: white !important;
  font-size: 2.5rem; /* Adjust size as needed */
  font-weight: bold; /* Make it stand out */
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5); /* Adds a subtle shadow for contrast */
  margin-bottom: 40px !important;

}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
 color: white;
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.3);
}

.form-group input:focus {

  outline: none;
  border-color: #007bff;
}

.form-group input::placeholder {
  color: #fff; /* Green placeholder text */

  opacity: 1; /* Optional: Make it slightly transparent */
}

.form-group input:focus::placeholder {
  color: transparent; /* Optional: Hides the placeholder on focus */
}

button {
  width: 100%;
  padding: 10px;
  background-color: #00cc66;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
   text-transform: uppercase;
   font-weight: bold
}

button:hover {
  background-color: #1aff8c;
}

.success {
  color: green;
  text-align: center;
  margin-top: 1rem;
}

.error {
  color: red;
  text-align: center;
  margin-top: 1rem;
}

p {
 color: white !important;
  margin-top: 1rem;

}

p a {
  color: #00cc66;
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}


