



.container {
  max-width: 700px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
   position: relative;
   background: transparent; /* Remove background color */
   z-index: 1; /* Ensure content is above the background */



}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);/* Semi-transparent white */
  backdrop-filter: blur(30px); /* Add blur effect */
  -webkit-backdrop-filter: blur(30px); /* For Safari */
  z-index: -1; /* Place behind the content */
  border-radius: 30px; /* Match the container's border-radius */
}

h1, h2 {
 font-family: 'Plus Jakarta Sans', sans-serif; /* Приложи шрифта */
     font-weight: 700;
 margin-top: 10px;
  color: #fff !important;
  text-align: center;
}

.date{
color: #fff !important;
 margin-top: -20px !important;

  position: absolute;
   top: 300px;
   right: 30px;


font-weight: bold;
font-size: 1.3em;
  margin: 20px 0;
}

.foodList {
   background: rgba(0, 0, 0, 0.3);/* Semi-transparent white */
    backdrop-filter: blur(30px); /* Add blur effect */
    -webkit-backdrop-filter: blur(30px);
  text-align: left;
    position: relative;

    padding-top: 50px; /* Ensure there's space for the button */
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: -10px !important;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #4CAF50;
}

.totalsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure even spacing between boxes */
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px; /* Add some margin at the top if needed */

   background: rgba(0, 130, 0, 0.3);/* Semi-transparent white */
      backdrop-filter: blur(30px); /* Add blur effect */
      -webkit-backdrop-filter: blur(30px);
  padding: 14px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #a4dbad;
}

.totalBox {
     background: rgba(200, 260, 200, 0.5);/* Semi-transparent white */
        backdrop-filter: blur(30px); /* Add blur effect */
        -webkit-backdrop-filter: blur(30px);
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 7px 6px rgba(0, 0, 0.20, 0.23);
  text-align: center;
  min-width: 120px;
}

.totalBox p {
  margin: 5px 0;
}

.profileIcon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.profileIcon img {
  width: 50px;
  filter: invert(80%);
  height: 50px;
  border-radius: 50%;
}

.profileIcon:hover {
  transform: scale(1.08);
}

.addProductButton {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 20px;
}

.addProductButton:hover {
  background-color: #218838;
}

.addProductForm {
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрира децата хоризонтално */
    margin-top: 15px;
}

.addProductForm input{
 margin-top: 5px;
  width: 80%; /* Make the input take 80% of the width of its container */
  padding: 8px; /* Add some padding for better readability */
  font-size: 10px; /* Increase the font size for better readability */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 12px; /* Add rounded corners */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 10px; /* Add space between the input and the suggestions */

}


.addProductForm select {
 margin-top: 5px;
  margin-bottom: 10px;
  margin-left: -50px;
  margin-right: -50px;
  min-width: 80%;
   background: #ccc;
   background-image: url('data:image/svg+xml;utf8,<svg fill="%23666" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5H7z"/></svg>');
     background-repeat: no-repeat;
     background-position: right 10px center;

  padding: 2px 5px; /* Намалява вътрешния падинг, като запазва удобство за текст */
  border: 1px solid #ccc;
  border-radius: 12px; /* По-заоблени ръбове */
  font-size: 10px;
  height: 30px; /* Принудително задава по-малка височина */
  appearance: none; /* Премахва стандартния стил на браузъра */
  -webkit-appearance: none;
  -moz-appearance: none;
}

.addProductForm button {
  align-self: flex-start;
}




.addMealForm {
  margin-top: 15px;
  display: flex;
  flex-direction: column;

}

.addMealForm input {
 margin-top: 5px;
  width: 80%; /* Make the input take 80% of the width of its container */
  padding: 8px; /* Add some padding for better readability */
  font-size: 10px; /* Increase the font size for better readability */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 12px; /* Add rounded corners */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 10px; /* Add space between the input and the suggestions */
}



.addButton {
 margin-bottom: 10px;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  padding: 10px 20px;
  background: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
}

.addButton:hover {
  background: #218838;
}




.goals {
  margin-top: 15px;

   background: rgba(240, 240, 140, 0.3);/* Semi-transparent white */
        backdrop-filter: blur(30px); /* Add blur effect */
        -webkit-backdrop-filter: blur(30px);
  padding: 1px;
  border-radius: 20px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 1.0em;

}

.goals h2 {
  margin-bottom: 10px;
  margin-top: 10px;
}



.goals {
  text-align: center;
}

.goalBox {
  margin: 10px;
  padding: 5px;
  border-radius: 15px;
 background: rgba(255, 231, 184, 0.8); /* 30% непрозрачност *//* Semi-transparent white */
         backdrop-filter: blur(30px); /* Add blur effect */
         -webkit-backdrop-filter: blur(30px);
           font-weight: bold;
             font-weight: 150;
             font-size: 14px;
}

.goalBox label {
 position: absolute;
  display: block;
   font-size: 16px;
    font-weight: 700;
  margin: 15px;
}

.goalBox progress {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  appearance: none;
}

progress::-webkit-progress-bar {
 margin-top: 10px;
  background-color: #fff; /* Background color of the progress bar */
  border-radius: 50px  50px; /* Rounded corners for the progress bar */

}

progress::-webkit-progress-value {
  background-color: #82c9f5; /* Color of the progress bar's value */
  border-radius: 50px  50px; /* Rounded corners for the filled part */
  border: 8px solid #c4e2f5;
  box-shadow: 8px 0 8px rgba(0, 0, 0, 0.3);

}

progress::-moz-progress-bar {
  background-color: #82c9f5; /* Color of the progress bar's value for Firefox */
  border-radius: 50px 0 0 50px; /* Rounded corners for the progress bar */
}

.progress::-webkit-progress-value:hover {
  transform: scale(1.02);
}

.withinLimit::-webkit-progress-value {
  background-color: #82c9f5; /* Синьо, ако е под 100% */
  border: 8px solid #c4e2f5;
}

.nearLimit::-webkit-progress-value {
  background-color: #6fdd80; /* Зелено между 100% и 110% */
  border: 8px solid #b6efc1;
}

.overLimit::-webkit-progress-value {
  background-color: #fc7c80; /* Червено над 110% */
  border: 8px solid #f2bdbf;
}

/* Firefox */
.withinLimit::-moz-progress-bar {
  background-color: #82c9f5;
}

.nearLimit::-moz-progress-bar {
  background-color: #6fdd80; /* Зелено */
}

.overLimit::-moz-progress-bar {
  background-color: #fc7c80; /* Червено */
}



.mealTable {
margin-bottom: -10px;

 width: 100%;
   border-collapse: separate; /* Use separate borders to allow rounding */
   border-spacing: 0; /* Remove space between cells */
   border-radius: 12px; /* Rounded edges for the entire table */
   overflow: hidden; /* Ensure rounded edges are applied */

}

.mealTable th, .mealTable td {

  padding: 2px;
}

.mealTable th {
  background-color: #b5f7bf;
  text-align: center;
}

.mealTable td {
  text-align: center;
  border: 0.3px solid #6e6d6d;
  background-color: #f5fff7;
}









.addMealButton {
  position: absolute;
  top: 10px;
  right:70px;
  width: 50px; /* Set width for the circular button */
  height: 50px; /* Set height for the circular button */
  background-color: #00cc66;
  color: white;
  border: none;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Ensure the plus sign is centered */
}

.addMealButton:hover {
  background-color: #00994d;
}

.suggestionContainer {
  position: relative;
   max-width: 100%;

}

.suggestionsList {

  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  list-style-type: none;
  padding: 0;
  margin: 4px 0 0 0;
}

.suggestion {
  background: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  align-items: center;
 width: calc(33.333% - 10px); /* Adjust width based on the desired layout */
  box-sizing: border-box;
}

.suggestionBlock {
  display: wrap;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.suggestion:hover {
  background: #e6e6e6;
}


.productDetails {
  margin-top: 5px;
  font-size: 12px;
}

.deleteButton {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 5px;
  cursor: pointer;
}

.addDeleteButtonWrapper {
  display: flex;
  justify-content: flex-end; /* Align the buttons to the right */
  margin-top: 10px;
}

.toggleDeleteButton {
background-color: red;
  padding: 10px 16px;
  border-radius: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.toggleDeleteButton:hover {
  background-color: #871426;
}

.deleteButton:hover {
  background-color: #871426;
}

/* Dropdown button styling */
.toggleDropdownButton {
  background-color: red;
  color: white;
  position: absolute;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  padding: 8px 16px;
  width: 50px; /* Set width for the circular button */
  height: 50px;
  border-radius: 50%;
  font-size: 24px;

}

.toggleDropdownButton:hover {
  background-color: #871426;
}

/* Dropdown content (hidden by default) */
.dropdownContent {
  display: none;
  position: absolute;
  top: 60px;
  right: 10; /* Align the dropdown under the toggle button */
  background-color: #f2f2f2;
  width: 100px; /* Consistent width for dropdown content */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1;
  border-radius: 10px;
  padding: 5px 0; /* Add padding for better spacing */
  text-align: center; /* Center text inside dropdown buttons */
}


/* Show dropdown content when dropdown button is clicked */
.addDeleteButtonWrapper .dropdownContent {
  display: block;
}

.dropdownContent button {
  color: white;
  width: 80px;
  padding: 12px 12px;
  text-align: center;
  border: 1px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);


}


/* Edit button styling */
.toggleEditButton {

  background-color: #ffc107; /* Yellow color for edit */
  color: black;
  border-radius: 0;
  width: 100%; /* Ensure the button spans the width of the dropdown */
  padding: 120px 16px; /* Match padding with the delete button */
  text-align: center; /* Center the text */
  cursor: pointer;
  margin: 0; /* Ensure no extra margin is added */
   margin-top: 5px;
}

.toggleEditButton:hover {
  background-color: #9e7805;
}









