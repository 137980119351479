.measurement-chart-container {
  position: relative;
  width: 100%;
  max-width: 550px; /* Limit width */
  margin: 20px auto; /* Center the chart */
  padding: 25px; /* Reduce padding */
  background: white;
  border-radius: 12px; /* Slightly smaller border radius */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Softer shadow */
}

.chartM-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: -10px;
   margin-top: -5px;
  color: #007bff!important;
}
.chartM-wrapper {
  width: 100%;
  height: 300px; /* Lower height for a more compact look */
  margin-bottom: 5px; /* Reduce bottom spacing */
}

canvas {
  max-height: 100%; /* Ensure canvas adapts to wrapper */
}
