
body.no-scroll {
  overflow: hidden;
}

.container {
  background: #fff;
  padding: 4rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: 100%;
  max-width: 300px;
  max-height: 700px;
  text-align: center;

}

.container h1 {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}

button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 20px;
  background:  #00cc66;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #1aff8c;
}

.success {
  color: green;
  margin-top: 1rem;
}

.error {
  color: red;
  margin-top: 1rem;
}

p {
  margin-top: 1rem;
  color: #555;
}

p a {
  color: #00cc66;
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}
